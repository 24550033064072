import { useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Routes from 'lib/utils/routes';
import { palette } from '@m12s/component-library';

// Components
import { AddWorkOrderModal } from 'components/JobTable/AddWorkOrderModal';

// Selectors
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getMachine } from 'lib/selectors/getMachine';
import { getNeedsForceLogin } from 'lib/selectors/getCanOpenLaborTicket';
import SwipeUpModal from 'components/SwipeUpModal';
import { Loading } from 'components/Loading';
import { getProductionScheduleEnabled } from 'lib/selectors/getProductionScheduleEnabled';
import useSelectOperator from 'lib/hooks/useSelectOperator';
import WorkOrderCard from './WorkOrderCard/index';

// misc
import { searchOperations } from './helpers';
import useSelectOperationFlow from './useSelectOperationFlow';

const WorkOrderContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 18.625rem);
  justify-content: center;
  background-color: ${palette.Grey100};
  padding: 1rem 1rem 2rem 1rem;
  height: fit-content;
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

const ControlsContainer = styled.section`
  border-top: solid 1px ${palette.Grey400};
  border-bottom: solid 1px ${palette.Grey300};
  padding: 1rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 1rem;
`;

const SelectWorkOrderOpModal = ({
  handleOnClose = () => {},
  nextStep,
  dispatch,
}) => {
  const productionScheduleEnabled = useSelector(getProductionScheduleEnabled);

  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [newRunModalOpen, setNewRunModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const machine = useSelector(getMachine);
  const needsForceLogin = useSelector(getNeedsForceLogin);
  const { openSelectOperator, selectOperatorOpen } = useSelectOperator();

  const redirectPath = Routes.machineIdSelectJobPath(machine.id);

  // Add useEffect for handling force login
  useEffect(() => {
    if (needsForceLogin && !selectOperatorOpen) {
      openSelectOperator({
        redirectPath,
      });
    }
  }, [needsForceLogin, selectOperatorOpen, openSelectOperator, redirectPath]);

  const {
    operations,
    pageLoading,
    excludeOperations,
    searchAll,
    search,
    error,
    schedule,
    Components,
  } = useSelectOperationFlow({
    setManualModalOpen,
    setNewRunModalOpen,
  });

  const {
    LoadMoreButton,
    Checkbox,
    SearchInput,
    EmptyContent,
    HeaderText,
  } = Components;

  const filteredOperations = useMemo(() => {
    if (!searchAll) {
      return searchOperations(search, operations);
    }
    return operations;
  }, [search, operations, searchAll]);

  const showEmptyMessage = error || pageLoading || !filteredOperations?.length;

  if (needsForceLogin && !selectOperatorOpen) {
    return <Loading />;
  }

  return (
    <SwipeUpModal
      id="select-work-order-op"
      isOpen={isOpen}
      header={HeaderText}
      onClose={() => {
        setIsOpen(false);
        handleOnClose();
      }}
      details={
        <>
          <ControlsContainer>
            {manualModalOpen && (
              <AddWorkOrderModal
                forEditWorkOrderId
                handleOnClose={() => {
                  return setManualModalOpen(false);
                }}
              />
            )}
            {newRunModalOpen && (
              <AddWorkOrderModal
                isStopStartNewRun
                handleOnClose={() => {
                  setNewRunModalOpen(false);
                }}
              />
            )}
            {SearchInput}
            {Checkbox}
          </ControlsContainer>
          {showEmptyMessage ? (
            EmptyContent
          ) : (
            <div
              style={{
                overflow: 'auto',
                display: 'grid',
                backgroundColor: palette.Grey100,
              }}
            >
              <WorkOrderContainer>
                {currentWorkOrderOp && (
                  <WorkOrderCard
                    active
                    workOrderOp={currentWorkOrderOp}
                    nextStep={nextStep}
                    dispatch={dispatch}
                    key={`${currentWorkOrderOp.workOrderId}-${currentWorkOrderOp.partNumber}-${currentWorkOrderOp.sequenceNumber}`}
                  />
                )}
                {filteredOperations.map((workOrderOp) => {
                  const { workOrder } = workOrderOp;
                  const blockingOperation =
                    productionScheduleEnabled &&
                    schedule?.find((operation) => {
                      return (
                        operation.workOrderId === workOrderOp.workOrderId &&
                        operation.sequenceNumber < workOrderOp.sequenceNumber
                      );
                    });

                  const excludeOperation = !!excludeOperations?.find(
                    (workOrderOperationRef) => {
                      return (
                        String(workOrderOp.workOrderOperationRef) ===
                        String(workOrderOperationRef)
                      );
                    }
                  );

                  return (
                    <WorkOrderCard
                      isBlockedBySequence={!!blockingOperation}
                      workOrderOp={workOrderOp}
                      exclude={excludeOperation}
                      nextStep={nextStep}
                      dispatch={dispatch}
                      key={`${workOrder.workOrderId}-${workOrder.partNumber}-${workOrderOp.sequenceNumber}`}
                    />
                  );
                })}
              </WorkOrderContainer>
              {LoadMoreButton}
            </div>
          )}
        </>
      }
    />
  );
};

SelectWorkOrderOpModal.propTypes = {
  nextStep: PropTypes.func,
  dispatch: PropTypes.func,
  handleOnClose: PropTypes.func,
};

export default SelectWorkOrderOpModal;
