import {
  actionCloseSelectOperator,
  actionOpenSelectOperator,
} from 'lib/actions';
import {
  getSelectOperatorOpen,
  getSelectOperatorOptions,
} from 'lib/selectors/getSelectOperator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useSelectOperator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectOperatorOpen = useSelector(getSelectOperatorOpen);
  const selectOperatorOptions = useSelector(getSelectOperatorOptions);

  const openSelectOperator = (options = {}) => {
    return dispatch(
      actionOpenSelectOperator({
        ...options,
      })
    );
  };

  const closeSelectOperator = (redirectPath) => {
    const path = redirectPath || selectOperatorOptions?.redirectPath;

    if (path) {
      history.push(path);
    }

    return dispatch(actionCloseSelectOperator());
  };

  return {
    selectOperatorOpen,
    selectOperatorOptions,
    openSelectOperator,
    closeSelectOperator,
  };
};

export default useSelectOperator;
