import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pill, palette, Icon } from '@m12s/component-library';

import { signIn, user } from 'lib/icons';

import { useSelector } from 'react-redux';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import useSelectOperator from 'lib/hooks/useSelectOperator';
import { PillText, PillWrapper } from './styled';

const StandardOperatorPill = () => {
  const { t } = useTranslation();

  const operator = useSelector(getCurrentOperator);
  const { openSelectOperator } = useSelectOperator();

  return (
    <PillWrapper
      id="operator-pill"
      onClick={() => {
        return openSelectOperator();
      }}
    >
      <Pill
        backgroundColor={operator ? palette.Purple100 : palette.Grey200}
        borderColor={operator ? palette.Purple200 : palette.Grey300}
        textColor={operator ? palette.Purple500 : palette.Grey700}
      >
        <Icon icon={operator ? user : signIn} size="1rem" />
        <PillText>{operator ? operator.name : t('Sign In Now')}</PillText>
      </Pill>
    </PillWrapper>
  );
};

export { StandardOperatorPill };
