import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { user, playRegular, signOut } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { UtilityMenu } from 'components/UtilityMenu';
import useOperatorLogout from 'components/LaborTicket/hooks/useOperatorLogout';
import useSelectOperator from 'lib/hooks/useSelectOperator';
import {
  ControlBarResponsive,
  MobileIcon,
  ResponsiveText,
  Scrollable,
} from '../styled';
import { CategorizeDowntime } from './CategorizeDowntime';
import { SwitchMachine } from './SwitchMachine';

/**
 * SignInAndSelectJob component displays different control bar options based on operator sign-in status.
 * When no operator is signed in, it shows sign-in button and optionally machine switching.
 * When operator is signed in but no job is running, it shows options to start job, categorize downtime, and sign out.
 *
 * @param {Object} props
 * @param {Object} props.history - React Router history object for navigation
 * @param {Object} props.machine - Machine object containing machine details
 * @param {Object|null} props.operator - Currently signed in operator or null if no one is signed in
 * @param {boolean} props.hasOpenActivitySet - Whether there is an open activity set
 * @param {boolean} [props.enableMachineSwitching=true] - Whether to show machine switching option
 */
const SignInAndSelectJob = ({
  history,
  machine,
  operator,
  hasOpenActivitySet,
  enableMachineSwitching = true,
}) => {
  const { t } = useTranslation();
  const requestOperatorLogout = useOperatorLogout();
  const { openSelectOperator } = useSelectOperator();

  // Signed Out, no job running
  if (!operator)
    return (
      <ControlBarResponsive id="job-control-bar">
        <Scrollable>
          <ControlBarButton
            id="job-control-bar-sign-in"
            onClick={() => {
              if (!hasOpenActivitySet) {
                return openSelectOperator({
                  redirectPath: Routes.machineIdSelectJobPath(machine.id),
                });
              }

              return openSelectOperator();
            }}
          >
            <MobileIcon icon={user} />
            <ResponsiveText
              full={t('signIn|full')}
              medium={t('signIn|medium')}
              short={t('signIn|short')}
            />
          </ControlBarButton>
          {enableMachineSwitching && <SwitchMachine history={history} />}
        </Scrollable>
      </ControlBarResponsive>
    );

  // Signed In, no job running
  return (
    <ControlBarResponsive id="job-control-bar">
      <UtilityMenu />

      <Scrollable>
        <ControlBarButton
          id="job-control-start-job"
          onClick={() => {
            return history.push(Routes.machineIdSelectJobPath(machine.id));
          }}
        >
          <MobileIcon icon={playRegular} />
          <ResponsiveText
            full={t('startJob|full')}
            medium={t('startJob|medium')}
            short={t('startJob|short')}
          />
        </ControlBarButton>

        <CategorizeDowntime history={history} machine={machine} />

        <ControlBarButton
          id="signout"
          onClick={() => {
            return requestOperatorLogout();
          }}
        >
          <MobileIcon icon={signOut} />
          <ResponsiveText
            full={t('signout|full')}
            medium={t('signout|medium')}
            short={t('signout|short')}
          />
        </ControlBarButton>
      </Scrollable>
    </ControlBarResponsive>
  );
};

SignInAndSelectJob.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  machine: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  operator: PropTypes.shape({
    // Add specific operator shape requirements if known
  }),
  hasOpenActivitySet: PropTypes.bool.isRequired,
  enableMachineSwitching: PropTypes.bool,
};

SignInAndSelectJob.defaultProps = {
  operator: null,
  enableMachineSwitching: true,
};

export { SignInAndSelectJob };
